// https://codepen.io/philhoyt/pen/ujHzd



#menu {
  padding-top:180px;
  margin-bottom: 30px;
}

.m-nav {
  display: none;
  cursor: pointer;
}

#menu ul {
  list-style:none;
  position:relative;
  margin:0;
  z-index: 1;
}

#menu ul a {
  display:block;
  padding:10px 30px;
  font-weight: bold;
  font-size: 1.1rem;
}

#menu ul li {
  position:relative;
  float:left;
  margin:0 7px;
  padding:0;

  &:first-child{
    margin-left:0;
  }
  &:last-child{
    margin-right:0;
  }
}

#menu ul li.current-menu-item {
  background:#ddd;
}

#menu ul li:hover {

}

#menu ul ul {
  display:none;
  position:absolute;
  top:100%;
  left:0;
  padding:0;
}

#menu ul ul li {
  float:none;
  width:235px;
  margin:2px 0;
  padding:5px 10px;
  background:yellow;
  text-align: left;


  a {
    background: none;
    color: red;
    font-weight: normal;
    font-size: 1rem;
  }

  &:hover {
    a {
      text-decoration: underline;
    }
  }
}

#menu ul ul a {
  // line-height:120%;
  padding:5px 15px 5px 10px;
}

#menu ul ul ul {
  top:0;
  left:100%
}

#menu ul li:hover > ul
{
  display:block;
}


@media all and (max-width: 1024px) {
  #menu ul {
    a {
      padding: 10px 15px;
    }
  }
}

@media all and (max-width: 850px) {
  #menu ul {
    a {
      padding: 10px 10px;
    }
  }
}

@media all and (max-width: 768px) {
  .m-nav {
    display: block;
    position: absolute;
    top:100px;
    right:25px;
  }

  #menu ul {
    display: none;
  }

  #menu.open {
    margin-bottom:20px;

    ul {
      display: block !important;
    }

    .slice:nth-child(1){
        transform: translateY(8px) rotate(225deg);
    }
    .slice:nth-child(2){
      opacity: 0;
    }
    .slice:nth-child(3){
      transform: translateY(-8px) rotate(-225deg);
    }
  }

  #menu {
    ul, li{
      // margin:0 !important;
      position: relative !important;
      float: none !important;
      width: 100% !important;
      padding:0 !important;
      text-align: center !important;
    }

    ul {
      margin: 0 !important;
      li {
        margin:2px 0 !important;
        display: block !important;
      }
    }

  }
}