
.wrapper {
  padding: 20px;
  //display: flex;
  //justify-content: space-around;
}

header {
  text-align: center;
}


.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
    text-align: center;
}

.video-container iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}

.grid {
    font-size:0;

    h3 {
      font-size: 1.25rem;
    }
    a {
      color:red;
    }
    .album, .p {
      display: inline-block;
      margin: 10px;
      max-width: calc(100%/3 - 20px); 

    }

    
}

/* --- LIGHTBOX --- */
.lightboxOverlay {
  background: #fff;
  opacity: 1;  
}

.lb-outerContainer {
  background:none;
}

.lightbox .lb-image {
  border:0px;
  border-radius:0;
}

.lb-data .lb-close {
  position: fixed;
  top:30px;
  right:20px;
  background:none;
  &:before, &:after {
    content:'';
    height:2px;
    width:30px;
    background-color:red;
    display: block;
    transform: rotate(45deg);
  }
  &:after{
    transform:rotate(-45deg);
    margin-top:-2px;
  }
}
.lb-data .lb-details {
  width: 100%;
  text-align: right;
}
.lb-data .lb-caption {
  a {
    color:red;
  }
}

.lb-cancel {
  width: 132px;
  height: 132px;
}

.lb-nav a.lb-prev {
  background:none;
  position: relative;
  &:before, &:after {
    position: absolute;
    top:50%;
    left:10px;  
    content:'';
    height:2px;
    width:30px;
    background-color:red;
    // display: block;
    transform: rotate(45deg);
  }
  &:after{
    transform:rotate(-45deg);
    margin-top:-20px;
  }
}

.lb-nav a.lb-next {
  background:none;
  position: relative;
  &:before, &:after {
    position: absolute;
    top:50%;
    right:10px;    
    content:'';
    height:2px;
    width:30px;
    background-color:red;
    display: block;
    transform: rotate(315deg);
  }
  &:after{
    transform:rotate(45deg);
    margin-top:-20px;
  }
}

/* --- MEDIA QUERIES --- */


@media all and (max-width: 1024px) {
  
}


@media all and (max-width: 965px) {

  .wrapper {
    padding: 0 20px;
  }

  .left,
  .middle,
  .right {
    position: relative;
    width: 100%;
    margin:10px 0;
    padding:0;
    left:0;
  }

  .left {
    margin-top: 0;
    margin-bottom: 30px;
  }
  .right {
    margin-bottom: 40px;
  }

  .main-nav li {
    display: inline-block;
  }

  footer {
    position: relative;
    margin-left:20px;
  }
  
}


@media all and (max-width: 800px) {

  
}


@media all and (max-width: 660px) {

  

}
