label {
  padding-left: 10px;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}


input, textarea{
  display: block;
  width: calc(100% - 24px);
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  margin:5px 0 30px 0;
}

textarea {
  height:100px;
}

input[type='file']{
  padding-top: 20px;
}

input[type='submit']{
  width: 100%;
}


.required{
  font-size: 0.85rem;
}

.radio_checkbox_group_legend {
  font-weight: bold;
  padding-left:10px;
  margin-top: 25px; 
}
.radio_checkbox_group {

  ul {
    padding:0 10px;
    margin:0 0 30px 0;
  }

  li {
    list-style: none;
    display: inline-block;
    margin:0 -4px 0 0;
    width: calc(50% - 4px);
  }

  input[type='checkbox'],
  input[type='radio']{
    display: inline-block;
    vertical-align: top;
    border:0px solid white;
    box-shadow: inset 0 0px 0px rgba(0,0,0,.075);
    margin:-4px 0 0 5px;
    width: 20px;
  }
  label {
    vertical-align: top;
    max-width: calc(100% - 50px);
    font-weight: normal;
  }
}

.formintro a {
  color: red;
}

.dd {
  label {
    margin-top: 25px;
    margin-bottom: 20px;
  }
  select{
    display: block;
    margin-left: 15px;
  }
}

.error {
  color:red;
  margin-bottom: 25px;

  ul {
    padding:0;
    margin: 0 0 0 30px;
    li {
      margin:0;
      padding:0;
    }
  }
}


@media all and (max-width: 768px) {
  .radio_checkbox_group {
    li{
      display: block;
      width: 100%;
    }
  }
}