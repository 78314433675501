// bower:scss
// endbower

@import 'typo';
@import 'variables';
@import 'extras';
@import 'layout';
@import 'form';
@import 'admin';
@import 'menu';


@font-face {
  font-family: 'ahamonomonospaced';
  src: url('../fonts/ahamono-monospaced-webfont.woff2') format('woff2'),
       url('../fonts/ahamono-monospaced-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

$maincolor: #FF0000;

html, body{
  -webkit-font-smoothing: antialiased;  
  font-family: 'ahamonomonospaced';
  font-size: 1.1rem;
}

body {
  counter-reset: section; /* Set the person counter to 0 */
}

img {
  max-width:100%;
  height: auto; 
}

sub {
  font-size: 0.85rem; 
}

.header {
  margin:30px 0;
  text-align: center;
}

.intro{
  padding:50px 0 0 0;
  font-size: 1rem;
}


.container, .wrapper {
  margin-bottom:30px;
  max-width: 950px;
  margin:0 auto;
}


nav {
  ul {
    padding:0;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // flex-grow: 1;
    justify-content: center;
    align-items: baseline;
    li {
      list-style: none;
      // display: inline-block;
      // margin:15px 0;
      margin:5px 5px 5px 0;
      a {
        background-color: $maincolor;
        color: yellow;
        padding:5px;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 0.92rem;

        &:hover {
          color: $maincolor;
          background-color: yellow;
          text-decoration: none;
        }
      }
    }
  }
}

section {
  margin-bottom: 75px;

  h2 {
    padding-top: 20px;
  }

  a {
    color: $maincolor;
  }
}


.form-group, .lead {
  font-size: 1rem;
}

input[type="radio"],
input[type="checkbox"] {
  margin-right: 10px; 
  padding-left:0;
  margin-left:0;
}

input[type="checkbox"]{
  margin: 6px 10px 0 0;
}

input[type="submit"]{
  background: yellow;
  border: 0;
  color: red;
  // margin-top: 20px;
  // margin-bottom: 20px;
}

.btn {
  background-color: red;
  color: yellow;
  padding:5px 10px;
  display: inline-block;
  margin:10px 0;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    background-color: yellow;
    color: red;
  }
}

label{
  padding-left:10px;
}
.hoverable{
  -webkit-transition: all 0.2s;
    transition: all 0.2s;
    padding-top:10px;
  padding-bottom:10px;
  margin-bottom:5px;  

  &:hover{
    background-color: $maincolor;
    //background-color:rgba(82,168,166,0.1);
    color:white;    
  }
}


h2 {  
  margin-top:0;
  font-size: 1.5rem;
  text-transform:uppercase;
  font-weight:700;
  margin-top:50px;
  margin-bottom:25px;
  color: $maincolor;
}

h3 {
  //font-size:30px;
  margin:40px 0 5px 0;
  text-transform:uppercase;
  font-weight:700;
  font-size: 1.15rem;
}

.sph3 {
  margin-top: 40px;
}

h4 {
  // display: inline-block;
  // background-color: $maincolor;
  padding:0;
  // text-transform: uppercase;
  // color:yellow;
  // margin-top:50px;
  margin-bottom:0; 
  color: $maincolor;
}


.content .intro {
  padding-top: 0;
}


.divider{
  width:100%;
  height:1px;
  background-color:black;
  margin:0 auto;
  margin-top:50px;
}

.checkbox {
  margin-top:0;
  margin-bottom:0;
}

.checkbox, .radiobutton {
  label {
    display: block;
  }
}

.form-title{
  background-color:$maincolor;
  padding:10px 15px;
  color:white;
  // text-transform:uppercase;
  font-weight:bold;
  margin-bottom:2px;
  cursor:pointer;
}

.programme-wrapper{
  display:none;
}

.row {
  margin-left:0;
  margin-right:0;
}

.col-sm-2 {
  font-weight:bold;
}


button,
.btn-default,
.remove_field {
  background-color: yellow;
  color: black;
  // font-family: "din-condensed-web",sans-serif;
  border:0px solid yellow;
  text-transform: uppercase;
  font-weight: bold;

  &:hover, &:active, &:focus{
    background-color:#ff0000;
    color:yellow;
  }
}

.btn-default {
  margin:50px 0;
}

.remove_field {
  display: inline-block;
  float: right;
}

.childage {
  margin-top: 10px;
}

.first {
  margin-top:100px !important;
}

.allergies {
  margin-top: 10px;
  textarea {
    margin-top: 5px;
  }
  display: none;
}

.price {
  position: fixed;
  width: 100%;
  background-color: #ff0000;
  color: yellow;
  text-transform: uppercase;
  bottom: 0;
  padding:0 10px;
}

.disclaimer {
  text-align: center;
  font-size: 0.9rem;
  margin-bottom: 40px;
  a {
    color: #333;

  }
}


.personwrap {
  position: relative;
}
.personwrap::before {
  position: absolute;
  left:-20px;
  top:46px;
  font-size: 2rem;
  counter-increment: section;  /* Increment the section counter*/
  content: counter(section);   /* Display the counter */
}

.nopad {
  padding-left:0;
}
.novat {
  padding-top: 5px;
}

.childcaregroup {
  display: none;
}

.spbox .checkbox {
  padding-top: 5px;
  padding-left: 25px;
}

.alert-success {
  background-color: $maincolor;
  color: yellow;
  margin-top: 75px;
  a {
    color: yellow;
    text-decoration: underline;
    font-weight: bold;
  }
}

.fb {
  max-width: 700px;
  text-align: center;
  margin:50px auto;
  a {
    color: black;
    font-weight: bold;
    text-decoration: underline;
  }
}

.formerrors {
  padding-top: 40px;
  color: red;
}


.recap {
  background-color: $maincolor;
  color: yellow;
  padding:20px 20px 40px 20px;
  // max-width: 500px;
  margin:0 auto;
}

::-webkit-input-placeholder {
   color: #D7D7D7!important;
}

:-moz-placeholder { /* Firefox 18- */
   color: #D7D7D7!important;
}

::-moz-placeholder {  /* Firefox 19+ */
   color: #D7D7D7!important;
}

:-ms-input-placeholder {  
   color: #D7D7D7!important;
}



// full version

.full.home {
  main {
    display: flex;
  }
}

.full {

  .wrapper {
    max-width: 1270px;
  }

  img {
    display: block;
  }

  header {
    margin: -30px 0 0 0;
    background-image: url(../images/atomium.jpg);
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 250px;
    
    .logo {
      margin-top: 15px;
      float: left;
      text-align: left;
      img{
        max-width: 450px;
      }

      text-decoration: none;

    }

    .eventdate {
      margin: -10px 0 0 5px;
      font-size: 0.93rem;
      color: black;
    }

    .bgimgs {
      margin:35px 0 15px 0;
      border:1px solid red;
      .bgimg {
        display: block;
      }
    }

    .slick-slider {
      z-index: -1;
    }
  }

  

  .left {
    width: calc(100%/3);
  }
  .right {
    width: calc(100%/3*2);
  }



  .news {
    // background-color: red;
    border:1px solid red;
    // display: inline-block;
    // margin: 0 -4px 0 0;
    
    h3 {
      background-color: red;
      margin:0;
      width: 100px;
      padding:5px 15px;
      text-align: center;
      display: inline-block;
      color: yellow;
    }
    a {
      text-decoration: none;
    }

    .intro {
      padding: 20px;
      color: black;
      h4{
        color: black;
        margin:0 0 10px 0;
      }
    }
    

    &.img {
      background-position:center center;
      background-repeat:no-repeat;
      background-size:cover;
      .intro{
        opacity: 0;
      }

      &:not(.nobody):hover {
        background-image:none !important;
        .intro {
          opacity: 1;
        }
      }
    }
    
  }

  .left {
    .social {
      text-transform: uppercase;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 40px;

      .fuo {
        display: inline-block;
        margin-right: 10px;
      }
      a {
        width: 37px;
        display: inline-block;
      }
      margin-bottom: 15px;
    }
    .btn {
      clear: both;
      display: block;
      width: calc(100% - 20px);
      text-align: center;
      font-size: 2rem;
      padding:10px;
      margin-bottom: 15px;
    }

    .news {
      .intro {
        padding: 30px 45px;
      }
    }

    .mainsponsors {
      margin:50px 0;
      padding:0 25px;
    }
    .mainsponsor {
      display: inline-block;
      max-width: 45%;
      margin:5px auto;
     text-align: center;

    }

  }

  .right .news {
    min-height: 400px;
    width: calc(100%/3 - 16px);
    float: left;
    margin: 0 0 15px 14px;
  }
  
}

.newspage {
      
  .news {
    display: inline-block;
    vertical-align: top;
    width: calc(100%/3 - 50px);
    padding:20px;
    margin-bottom: 15px;
    min-height: 250px;

    h3, h3 a {
      background: none;
      width: auto;
      color: $maincolor;
    }

    p {
      color: black;
    }

    h3 {
      padding:0;
      margin:0;
    }

    .intro {
      padding:10px 0 0 0;
    }

    &.img {
      h3{
        opacity: 0;
      }

      &:not(.nobody):hover {
        
        h3 {
          opacity: 1;
        }
      }
    }
  }
}

.detail {
  h1 {
    color: $maincolor;
    text-transform: uppercase;
  }

  .newsimg, .newsbody {
    display: inline-block;
    vertical-align: top;
  }
  .newsimg {
    width: 20%;
    max-width: 300px;
    margin-right: 20px;
    border:1px solid red;
  }
  .newsbody {
    width: calc(80% - 55px);

    a {
      color:red
    }
  }
}

.pager {
  text-align: center;
  color: #DEDEDE;
  a {
    color:red;
  }
}

.generalpage {
  main {
    max-width: 900px;
    margin: 0 auto;
    a {
      color: $maincolor;
    }
  }

  h1 {
    color: $maincolor;
    text-transform: uppercase;
  }
}


.sponsors {
  a {
    display: inline-block;
    max-width: 30%;
    margin:10px auto;
   text-align: center;
   vertical-align: middle;

  }

  &.gold {
    a {max-width: 25%;}
  }
  &.silver {
    a {max-width: 20%;}
  }
  &.bronze {
    a {max-width: 15%;}
  }
}

@media all and (max-width: 1175px) {
  .full {
    .right .news {
      width: calc(100%/2 - 16px);      
    }
  }

  .newspage .news {
    width: calc(100%/2 - 50px);
  }
}


@media all and (max-width: 768px) {
  .personwrap::before {
    font-size: 2rem;
    left:-10px;
    top: 0;
  }

   .full {

    header .logo img{
      max-width: 65%;//350px;
    }


    header {
      background-size: 150px;

      .bgimgs {
        margin-top:0 !important;
      }

      .eventdate {
        margin-top: 0;
      }

    }
    .right, .left {
      width: 50%;      
    }

    .right .news {
      width: calc(100% - 16px);      
      min-height: auto;
    }
    .right .news.img {
      min-height: 300px; 
    }
  }

  .newspage .news {
    width: calc(100% - 40px);
    min-height: auto;
  }

  #menu {
    margin-bottom: 0;
  }

}

@media all and (max-width: 600px) {
  
   .full {

    main {
      display: block !important;
    }
    .right, .left {
      display: block;
      width: 100%;
      margin-bottom:10px;

      .news {
        display: block;
        width: 100%;
        margin:10px 0;

        .intro {
          padding:20px;
        }
      }
    }

    // .right .news {
    //   width: calc(100% - 16px);      
    //   min-height: auto;
    // }
  }

}


@media all and (max-width: 400px) {

  .full {
     header {
      margin: -10px 0 0 0;
      background-size: 100px;

      .logo {
        margin-top: 30px;
      }

      .eventdate {
        margin-top: 0;
      }
      
    }

  }
  .m-nav {
    top: 80px;
  }

  #menu {
    padding-top:140px;    
  }

  nav ul {
    flex-direction: column;
    // flex-wrap: nowrap;
    justify-content: space-around;
    li{
      width: calc(100% - 10px);
      text-align: center;
      margin:5px 0 0 0;
      a {
        display: block;
      }
      
    }

  }
}