.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.hamburger {
  display: none;
  position: fixed;
  top:11px;
  right:20px;
  cursor: pointer;
  z-index: 666;
} 

.slice {
  background-color: red;
  height:3px;
  width:25px;
  margin:5px;
  -webkit-transition: all 0.3s  cubic-bezier(0.445, 0.050, 0.550, 0.950);  /* Saf3.2+, Chrome */
  -moz-transition: all 0.3s  cubic-bezier(0.445, 0.050, 0.550, 0.950);  /* FF4+ */
  -ms-transition: all 0.3s  cubic-bezier(0.445, 0.050, 0.550, 0.950);  /* IE10? */
  -o-transition: all 0.3s  cubic-bezier(0.445, 0.050, 0.550, 0.950);  /* Opera 10.5+ */
  transition: all 0.3s  cubic-bezier(0.445, 0.050, 0.550, 0.950);
}