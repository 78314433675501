.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.hamburger {
  display: none;
  position: fixed;
  top: 11px;
  right: 20px;
  cursor: pointer;
  z-index: 666;
}

.slice {
  background-color: red;
  height: 3px;
  width: 25px;
  margin: 5px;
  -webkit-transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  /* Saf3.2+, Chrome */
  -moz-transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  /* FF4+ */
  -ms-transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  /* IE10? */
  -o-transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  /* Opera 10.5+ */
  transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.wrapper {
  padding: 20px;
}

header {
  text-align: center;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;
  text-align: center;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.grid {
  font-size: 0;
}

.grid h3 {
  font-size: 1.25rem;
}

.grid a {
  color: red;
}

.grid .album, .grid .p {
  display: inline-block;
  margin: 10px;
  max-width: calc(100%/3 - 20px);
}

/* --- LIGHTBOX --- */
.lightboxOverlay {
  background: #fff;
  opacity: 1;
}

.lb-outerContainer {
  background: none;
}

.lightbox .lb-image {
  border: 0px;
  border-radius: 0;
}

.lb-data .lb-close {
  position: fixed;
  top: 30px;
  right: 20px;
  background: none;
}

.lb-data .lb-close:before, .lb-data .lb-close:after {
  content: '';
  height: 2px;
  width: 30px;
  background-color: red;
  display: block;
  transform: rotate(45deg);
}

.lb-data .lb-close:after {
  transform: rotate(-45deg);
  margin-top: -2px;
}

.lb-data .lb-details {
  width: 100%;
  text-align: right;
}

.lb-data .lb-caption a {
  color: red;
}

.lb-cancel {
  width: 132px;
  height: 132px;
}

.lb-nav a.lb-prev {
  background: none;
  position: relative;
}

.lb-nav a.lb-prev:before, .lb-nav a.lb-prev:after {
  position: absolute;
  top: 50%;
  left: 10px;
  content: '';
  height: 2px;
  width: 30px;
  background-color: red;
  transform: rotate(45deg);
}

.lb-nav a.lb-prev:after {
  transform: rotate(-45deg);
  margin-top: -20px;
}

.lb-nav a.lb-next {
  background: none;
  position: relative;
}

.lb-nav a.lb-next:before, .lb-nav a.lb-next:after {
  position: absolute;
  top: 50%;
  right: 10px;
  content: '';
  height: 2px;
  width: 30px;
  background-color: red;
  display: block;
  transform: rotate(315deg);
}

.lb-nav a.lb-next:after {
  transform: rotate(45deg);
  margin-top: -20px;
}

/* --- MEDIA QUERIES --- */
@media all and (max-width: 965px) {
  .wrapper {
    padding: 0 20px;
  }
  .left,
  .middle,
  .right {
    position: relative;
    width: 100%;
    margin: 10px 0;
    padding: 0;
    left: 0;
  }
  .left {
    margin-top: 0;
    margin-bottom: 30px;
  }
  .right {
    margin-bottom: 40px;
  }
  .main-nav li {
    display: inline-block;
  }
  footer {
    position: relative;
    margin-left: 20px;
  }
}

label {
  padding-left: 10px;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

input, textarea {
  display: block;
  width: calc(100% - 24px);
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  margin: 5px 0 30px 0;
}

textarea {
  height: 100px;
}

input[type='file'] {
  padding-top: 20px;
}

input[type='submit'] {
  width: 100%;
}

.required {
  font-size: 0.85rem;
}

.radio_checkbox_group_legend {
  font-weight: bold;
  padding-left: 10px;
  margin-top: 25px;
}

.radio_checkbox_group ul {
  padding: 0 10px;
  margin: 0 0 30px 0;
}

.radio_checkbox_group li {
  list-style: none;
  display: inline-block;
  margin: 0 -4px 0 0;
  width: calc(50% - 4px);
}

.radio_checkbox_group input[type='checkbox'],
.radio_checkbox_group input[type='radio'] {
  display: inline-block;
  vertical-align: top;
  border: 0px solid white;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
  margin: -4px 0 0 5px;
  width: 20px;
}

.radio_checkbox_group label {
  vertical-align: top;
  max-width: calc(100% - 50px);
  font-weight: normal;
}

.formintro a {
  color: red;
}

.dd label {
  margin-top: 25px;
  margin-bottom: 20px;
}

.dd select {
  display: block;
  margin-left: 15px;
}

.error {
  color: red;
  margin-bottom: 25px;
}

.error ul {
  padding: 0;
  margin: 0 0 0 30px;
}

.error ul li {
  margin: 0;
  padding: 0;
}

@media all and (max-width: 768px) {
  .radio_checkbox_group li {
    display: block;
    width: 100%;
  }
}

/* custom edit buttons */
.edit-block {
  position: relative;
}

.edit-block a {
  color: black !important;
  position: relative;
}

.edit-block a:hover {
  color: black !important;
}

.edit-block a:hover:after {
  content: 'edit';
  font-size: 0.65rem;
  position: absolute;
  left: 18px;
}

/* admin bar colors */
.admin_bar_wrapper {
  background-color: black !important;
}

#menu {
  padding-top: 180px;
  margin-bottom: 30px;
}

.m-nav {
  display: none;
  cursor: pointer;
}

#menu ul {
  list-style: none;
  position: relative;
  margin: 0;
  z-index: 1;
}

#menu ul a {
  display: block;
  padding: 10px 30px;
  font-weight: bold;
  font-size: 1.1rem;
}

#menu ul li {
  position: relative;
  float: left;
  margin: 0 7px;
  padding: 0;
}

#menu ul li:first-child {
  margin-left: 0;
}

#menu ul li:last-child {
  margin-right: 0;
}

#menu ul li.current-menu-item {
  background: #ddd;
}

#menu ul ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0;
}

#menu ul ul li {
  float: none;
  width: 235px;
  margin: 2px 0;
  padding: 5px 10px;
  background: yellow;
  text-align: left;
}

#menu ul ul li a {
  background: none;
  color: red;
  font-weight: normal;
  font-size: 1rem;
}

#menu ul ul li:hover a {
  text-decoration: underline;
}

#menu ul ul a {
  padding: 5px 15px 5px 10px;
}

#menu ul ul ul {
  top: 0;
  left: 100%;
}

#menu ul li:hover > ul {
  display: block;
}

@media all and (max-width: 1024px) {
  #menu ul a {
    padding: 10px 15px;
  }
}

@media all and (max-width: 850px) {
  #menu ul a {
    padding: 10px 10px;
  }
}

@media all and (max-width: 768px) {
  .m-nav {
    display: block;
    position: absolute;
    top: 100px;
    right: 25px;
  }
  #menu ul {
    display: none;
  }
  #menu.open {
    margin-bottom: 20px;
  }
  #menu.open ul {
    display: block !important;
  }
  #menu.open .slice:nth-child(1) {
    transform: translateY(8px) rotate(225deg);
  }
  #menu.open .slice:nth-child(2) {
    opacity: 0;
  }
  #menu.open .slice:nth-child(3) {
    transform: translateY(-8px) rotate(-225deg);
  }
  #menu ul, #menu li {
    position: relative !important;
    float: none !important;
    width: 100% !important;
    padding: 0 !important;
    text-align: center !important;
  }
  #menu ul {
    margin: 0 !important;
  }
  #menu ul li {
    margin: 2px 0 !important;
    display: block !important;
  }
}

@font-face {
  font-family: 'ahamonomonospaced';
  src: url("../fonts/ahamono-monospaced-webfont.woff2") format("woff2"), url("../fonts/ahamono-monospaced-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html, body {
  -webkit-font-smoothing: antialiased;
  font-family: 'ahamonomonospaced';
  font-size: 1.1rem;
}

body {
  counter-reset: section;
  /* Set the person counter to 0 */
}

img {
  max-width: 100%;
  height: auto;
}

sub {
  font-size: 0.85rem;
}

.header {
  margin: 30px 0;
  text-align: center;
}

.intro {
  padding: 50px 0 0 0;
  font-size: 1rem;
}

.container, .wrapper {
  margin-bottom: 30px;
  max-width: 950px;
  margin: 0 auto;
}

nav ul {
  padding: 0;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
}

nav ul li {
  list-style: none;
  margin: 5px 5px 5px 0;
}

nav ul li a {
  background-color: #FF0000;
  color: yellow;
  padding: 5px;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.92rem;
}

nav ul li a:hover {
  color: #FF0000;
  background-color: yellow;
  text-decoration: none;
}

section {
  margin-bottom: 75px;
}

section h2 {
  padding-top: 20px;
}

section a {
  color: #FF0000;
}

.form-group, .lead {
  font-size: 1rem;
}

input[type="radio"],
input[type="checkbox"] {
  margin-right: 10px;
  padding-left: 0;
  margin-left: 0;
}

input[type="checkbox"] {
  margin: 6px 10px 0 0;
}

input[type="submit"] {
  background: yellow;
  border: 0;
  color: red;
}

.btn {
  background-color: red;
  color: yellow;
  padding: 5px 10px;
  display: inline-block;
  margin: 10px 0;
  text-decoration: none;
  text-transform: uppercase;
}

.btn:hover {
  background-color: yellow;
  color: red;
}

label {
  padding-left: 10px;
}

.hoverable {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.hoverable:hover {
  background-color: #FF0000;
  color: white;
}

h2 {
  margin-top: 0;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 50px;
  margin-bottom: 25px;
  color: #FF0000;
}

h3 {
  margin: 40px 0 5px 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.15rem;
}

.sph3 {
  margin-top: 40px;
}

h4 {
  padding: 0;
  margin-bottom: 0;
  color: #FF0000;
}

.content .intro {
  padding-top: 0;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: black;
  margin: 0 auto;
  margin-top: 50px;
}

.checkbox {
  margin-top: 0;
  margin-bottom: 0;
}

.checkbox label, .radiobutton label {
  display: block;
}

.form-title {
  background-color: #FF0000;
  padding: 10px 15px;
  color: white;
  font-weight: bold;
  margin-bottom: 2px;
  cursor: pointer;
}

.programme-wrapper {
  display: none;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.col-sm-2 {
  font-weight: bold;
}

button,
.btn-default,
.remove_field {
  background-color: yellow;
  color: black;
  border: 0px solid yellow;
  text-transform: uppercase;
  font-weight: bold;
}

button:hover, button:active, button:focus,
.btn-default:hover,
.btn-default:active,
.btn-default:focus,
.remove_field:hover,
.remove_field:active,
.remove_field:focus {
  background-color: #ff0000;
  color: yellow;
}

.btn-default {
  margin: 50px 0;
}

.remove_field {
  display: inline-block;
  float: right;
}

.childage {
  margin-top: 10px;
}

.first {
  margin-top: 100px !important;
}

.allergies {
  margin-top: 10px;
  display: none;
}

.allergies textarea {
  margin-top: 5px;
}

.price {
  position: fixed;
  width: 100%;
  background-color: #ff0000;
  color: yellow;
  text-transform: uppercase;
  bottom: 0;
  padding: 0 10px;
}

.disclaimer {
  text-align: center;
  font-size: 0.9rem;
  margin-bottom: 40px;
}

.disclaimer a {
  color: #333;
}

.personwrap {
  position: relative;
}

.personwrap::before {
  position: absolute;
  left: -20px;
  top: 46px;
  font-size: 2rem;
  counter-increment: section;
  /* Increment the section counter*/
  content: counter(section);
  /* Display the counter */
}

.nopad {
  padding-left: 0;
}

.novat {
  padding-top: 5px;
}

.childcaregroup {
  display: none;
}

.spbox .checkbox {
  padding-top: 5px;
  padding-left: 25px;
}

.alert-success {
  background-color: #FF0000;
  color: yellow;
  margin-top: 75px;
}

.alert-success a {
  color: yellow;
  text-decoration: underline;
  font-weight: bold;
}

.fb {
  max-width: 700px;
  text-align: center;
  margin: 50px auto;
}

.fb a {
  color: black;
  font-weight: bold;
  text-decoration: underline;
}

.formerrors {
  padding-top: 40px;
  color: red;
}

.recap {
  background-color: #FF0000;
  color: yellow;
  padding: 20px 20px 40px 20px;
  margin: 0 auto;
}

::-webkit-input-placeholder {
  color: #D7D7D7 !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #D7D7D7 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #D7D7D7 !important;
}

:-ms-input-placeholder {
  color: #D7D7D7 !important;
}

.full.home main {
  display: flex;
}

.full .wrapper {
  max-width: 1270px;
}

.full img {
  display: block;
}

.full header {
  margin: -30px 0 0 0;
  background-image: url(../images/atomium.jpg);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 250px;
}

.full header .logo {
  margin-top: 15px;
  float: left;
  text-align: left;
  text-decoration: none;
}

.full header .logo img {
  max-width: 450px;
}

.full header .eventdate {
  margin: -10px 0 0 5px;
  font-size: 0.93rem;
  color: black;
}

.full header .bgimgs {
  margin: 35px 0 15px 0;
  border: 1px solid red;
}

.full header .bgimgs .bgimg {
  display: block;
}

.full header .slick-slider {
  z-index: -1;
}

.full .left {
  width: calc(100%/3);
}

.full .right {
  width: calc(100%/3*2);
}

.full .news {
  border: 1px solid red;
}

.full .news h3 {
  background-color: red;
  margin: 0;
  width: 100px;
  padding: 5px 15px;
  text-align: center;
  display: inline-block;
  color: yellow;
}

.full .news a {
  text-decoration: none;
}

.full .news .intro {
  padding: 20px;
  color: black;
}

.full .news .intro h4 {
  color: black;
  margin: 0 0 10px 0;
}

.full .news.img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.full .news.img .intro {
  opacity: 0;
}

.full .news.img:not(.nobody):hover {
  background-image: none !important;
}

.full .news.img:not(.nobody):hover .intro {
  opacity: 1;
}

.full .left .social {
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  margin-bottom: 15px;
}

.full .left .social .fuo {
  display: inline-block;
  margin-right: 10px;
}

.full .left .social a {
  width: 37px;
  display: inline-block;
}

.full .left .btn {
  clear: both;
  display: block;
  width: calc(100% - 20px);
  text-align: center;
  font-size: 2rem;
  padding: 10px;
  margin-bottom: 15px;
}

.full .left .news .intro {
  padding: 30px 45px;
}

.full .left .mainsponsors {
  margin: 50px 0;
  padding: 0 25px;
}

.full .left .mainsponsor {
  display: inline-block;
  max-width: 45%;
  margin: 5px auto;
  text-align: center;
}

.full .right .news {
  min-height: 400px;
  width: calc(100%/3 - 16px);
  float: left;
  margin: 0 0 15px 14px;
}

.newspage .news {
  display: inline-block;
  vertical-align: top;
  width: calc(100%/3 - 50px);
  padding: 20px;
  margin-bottom: 15px;
  min-height: 250px;
}

.newspage .news h3, .newspage .news h3 a {
  background: none;
  width: auto;
  color: #FF0000;
}

.newspage .news p {
  color: black;
}

.newspage .news h3 {
  padding: 0;
  margin: 0;
}

.newspage .news .intro {
  padding: 10px 0 0 0;
}

.newspage .news.img h3 {
  opacity: 0;
}

.newspage .news.img:not(.nobody):hover h3 {
  opacity: 1;
}

.detail h1 {
  color: #FF0000;
  text-transform: uppercase;
}

.detail .newsimg, .detail .newsbody {
  display: inline-block;
  vertical-align: top;
}

.detail .newsimg {
  width: 20%;
  max-width: 300px;
  margin-right: 20px;
  border: 1px solid red;
}

.detail .newsbody {
  width: calc(80% - 55px);
}

.detail .newsbody a {
  color: red;
}

.pager {
  text-align: center;
  color: #DEDEDE;
}

.pager a {
  color: red;
}

.generalpage main {
  max-width: 900px;
  margin: 0 auto;
}

.generalpage main a {
  color: #FF0000;
}

.generalpage h1 {
  color: #FF0000;
  text-transform: uppercase;
}

.sponsors a {
  display: inline-block;
  max-width: 30%;
  margin: 10px auto;
  text-align: center;
  vertical-align: middle;
}

.sponsors.gold a {
  max-width: 25%;
}

.sponsors.silver a {
  max-width: 20%;
}

.sponsors.bronze a {
  max-width: 15%;
}

@media all and (max-width: 1175px) {
  .full .right .news {
    width: calc(100%/2 - 16px);
  }
  .newspage .news {
    width: calc(100%/2 - 50px);
  }
}

@media all and (max-width: 768px) {
  .personwrap::before {
    font-size: 2rem;
    left: -10px;
    top: 0;
  }
  .full header .logo img {
    max-width: 65%;
  }
  .full header {
    background-size: 150px;
  }
  .full header .bgimgs {
    margin-top: 0 !important;
  }
  .full header .eventdate {
    margin-top: 0;
  }
  .full .right, .full .left {
    width: 50%;
  }
  .full .right .news {
    width: calc(100% - 16px);
    min-height: auto;
  }
  .full .right .news.img {
    min-height: 300px;
  }
  .newspage .news {
    width: calc(100% - 40px);
    min-height: auto;
  }
  #menu {
    margin-bottom: 0;
  }
}

@media all and (max-width: 600px) {
  .full main {
    display: block !important;
  }
  .full .right, .full .left {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  .full .right .news, .full .left .news {
    display: block;
    width: 100%;
    margin: 10px 0;
  }
  .full .right .news .intro, .full .left .news .intro {
    padding: 20px;
  }
}

@media all and (max-width: 400px) {
  .full header {
    margin: -10px 0 0 0;
    background-size: 100px;
  }
  .full header .logo {
    margin-top: 30px;
  }
  .full header .eventdate {
    margin-top: 0;
  }
  .m-nav {
    top: 80px;
  }
  #menu {
    padding-top: 140px;
  }
  nav ul {
    flex-direction: column;
    justify-content: space-around;
  }
  nav ul li {
    width: calc(100% - 10px);
    text-align: center;
    margin: 5px 0 0 0;
  }
  nav ul li a {
    display: block;
  }
}
